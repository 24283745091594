import classNames from 'classnames';
import React from 'react';

export const SubscriberStatus = ({ status }) => {
  const NEW_SUBSCRIBER_STATUS = 'success';
  const EXISTING_SUBSCRIBER_STATUS = 'exists';

  const propClasses = classNames({
    'bg-blue-400': status === NEW_SUBSCRIBER_STATUS,
    'bg-orange-400': status === EXISTING_SUBSCRIBER_STATUS,
  });

  const iconClass = classNames({
    'fa-check': status === NEW_SUBSCRIBER_STATUS,
    'fa-list': status === EXISTING_SUBSCRIBER_STATUS,
  });
  const iconBgClass = classNames({
    'bg-primary-600': status === NEW_SUBSCRIBER_STATUS,
    'bg-yellow-600': status === EXISTING_SUBSCRIBER_STATUS,
  });

  const statusText =
    status === NEW_SUBSCRIBER_STATUS ? 'Thanks for subscribing!' : 'Already subscribed.';

  return (
    <div className={`flex rounded items-center ${propClasses} mt-2 mb-4 px-3 py-4 max-w-5xl`}>
      <span
        className={`flex justify-center items-center w-10 h-10 mr-2 ${iconBgClass} rounded-full`}
      >
        <i className={`fa ${iconClass} fa-lg text-white`} />
      </span>
      <span className="font-display text-white">{statusText}</span>
    </div>
  );
};

export default SubscriberStatus;

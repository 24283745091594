/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import validator from 'email-validator';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { useState } from 'react';
import { SubscriberStatus } from './ui/status/status';

export default function NewsletterForm() {
  const [status, setStatus] = useState('');

  function NewsletterStandard() {
    const [email, setEmail] = useState('');
    const [valid, setValid] = useState(true);

    const handleSubmit = (e) => {
      e.preventDefault();

      setValid(validator.validate(email));
      if (valid) {
        addToMailchimp(email, { PATH: global.location.pathname })
          .then(({ result, msg }) => {
            if (result === 'error') {
              if (msg.includes('already subscribed')) {
                setStatus('exists');
              }
            } else {
              setStatus('success');
            }
          })
          .catch(() => {});
      }
    };

    const handleEmailChange = (e) => {
      setEmail(e.currentTarget.value);
      if (!valid) {
        setValid(true);
      }
    };

    return (
      <div className="flex flex-col my-4">
        <div className="flex flex-col md:flex-row justify-center font-sans">
          <form
            onSubmit={handleSubmit}
            css={css`
              & > input,
              & > button {
                flex: 1 1 10ch;
                margin: 0.5rem;
                border-radius: 0.25rem;
                padding: 0.75rem 1rem;

                &[type='email'] {
                  flex: 3 1 40ch;
                  background: var(--bgInput);
                }
                &[type='submit'] {
                  color: white;
                }
              }
            `}
          >
            <input
              type="email"
              className={!valid ? 'border border-red-400' : ''}
              placeholder="Email Address"
              onChange={handleEmailChange}
            />
            <button type="submit" className="shadow-lg bg-primary-500 hover:bg-primary-600">
              Subscribe
            </button>
          </form>
        </div>
        <span className={`text-xs font-mono ml-2 text-red-400 ${!valid ? 'block' : 'hidden'}`}>
          Please enter a valid email address.
        </span>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      {' '}
      {(() => {
        switch (status) {
          case '':
            return <NewsletterStandard />;
          case 'exists':
            return <SubscriberStatus status="exists" />;
          case 'success':
            return <SubscriberStatus status="success" />;
          default:
            return <NewsletterStandard />;
        }
      })()}
    </div>
  );
}
